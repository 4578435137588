import React from 'react';

import BlogPost, { BlogPostProps } from './BlogPost';

interface BlogListingProps {
  blogPosts: BlogPostProps[];
}

const BlogListing = ({ blogPosts }: BlogListingProps) => {
  return (
    <ul className="flex flex-col gap-24 lg:gap-40">
      {blogPosts.map((post) => (
        <BlogPost key={post.guid} {...post} />
      ))}
    </ul>
  );
};

export default BlogListing;
