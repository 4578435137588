import { Trans } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useRef, useState } from 'react';

import { MediumBlogPostsItem } from '../../../services/types';
import { formatPublicationDate } from '../../../services/utils';

export type BlogPostProps = MediumBlogPostsItem;

const BlogPost = ({ title, pubDate, link, thumbnail, description }: BlogPostProps) => {
  const descriptionRef = useRef<HTMLDivElement>(null);

  const [parsedDescription, setParsedDescription] = useState<string | null>(null);

  useEffect(() => {
    if (!descriptionRef?.current) {
      return;
    }

    const descriptionParagraphs = descriptionRef.current.getElementsByTagName('p');

    if (descriptionParagraphs.length === 0) {
      return;
    }

    setParsedDescription(descriptionParagraphs[0].innerHTML.replace(/(<([^>]+)>)/gi, ''));
  }, [descriptionRef]);

  return (
    <li>
      <div className="hidden" ref={descriptionRef} dangerouslySetInnerHTML={{ __html: description }} />
      <div className="text-volcaneers-black flex flex-col md:flex-row md:gap-24 lg:gap-48">
        <a href={link} target="_blank" className="block">
          <div className="md:w-[220px] md:h-[200px] lg:w-[340px] lg:h-[192px]">
            <img src={thumbnail} alt="" className="rounded-[12px] md:object-cover md:w-full md:h-full" />
          </div>
        </a>
        <div className="mt-16 md:mt-0 md:flex-1 md:flex md:flex-col">
          <a href={link} target="_blank" className="block">
            <h3 className="font-bold font-heading text-16 leading-5 md:text-20 md:leading-8">{title}</h3>
          </a>
          {parsedDescription && (
            <p className="text16 leading-6 mt-16" dangerouslySetInnerHTML={{ __html: parsedDescription }} />
          )}
          <div className="pt-16 mt-auto flex items-center justify-between">
            <p className="text-16 leading-4 text-[#7A7B6F]">{formatPublicationDate(pubDate)}</p>
            <a href={link} target="_blank" className="text-16 leading-4 text-[#2DCA91] underline">
              <Trans>Read more</Trans>
            </a>
          </div>
        </div>
      </div>
    </li>
  );
};

export default BlogPost;
