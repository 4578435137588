import { Trans } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';

import OvalIllustration from '../../../../static/images/illustration-oval.inline.svg';
import { MediumBlogPostsResponse } from '../../../services/types';
import { fetchMediumBlogPosts } from '../../../services/utils';

import FullwidthIllustration from '../volcaneers/FullwidthIllustration';
import BlogListing from './BlogListing';
import BlogPinnedPost from './BlogPinnedPost';

const BlogSection = () => {
  const [data, setData] = useState<MediumBlogPostsResponse | null>(null);

  useEffect(() => {
    (async () => {
      const data = await fetchMediumBlogPosts();
      setData(data);
    })();
  }, []);

  return (
    <section>
      <div className="container-slim pt-24 md:pt-32 pb-[24vw] md:pb-[10vw] xl:pb-[5vw] 2xl:pb-[0] relative z-30">
        <div className="md:flex md:items-center md:justify-between">
          <h1 className="text-28 leading-7 font-bold text-black">
            <Trans>Blog</Trans>
          </h1>
        </div>
        {data && data.status === 'ok' && (
          <div>
            <div className="mt-24 md:mt-32">
              <BlogPinnedPost {...data.items[0]} />
            </div>
            <div className="mt-48 md:mt-56 lg:mt-64">
              <BlogListing blogPosts={data.items.slice(1, data.items.length)} />
            </div>
          </div>
        )}
      </div>
      <FullwidthIllustration illustration={<OvalIllustration />} containerClasses="pt-[10vw] fill-volcaneers-black" />
    </section>
  );
};

export default BlogSection;
