import { Trans } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useRef, useState } from 'react';

import { MediumBlogPostsItem } from '../../../services/types';
import { formatPublicationDate } from '../../../services/utils';

export type BlogPinnedPostProps = MediumBlogPostsItem;

const BlogPinnedPost = ({ title, pubDate, link, thumbnail, description }: BlogPinnedPostProps) => {
  const descriptionRef = useRef<HTMLDivElement>(null);

  const [parsedDescription, setParsedDescription] = useState<string | null>(null);

  useEffect(() => {
    if (!descriptionRef?.current) {
      return;
    }

    const descriptionParagraphs = descriptionRef.current.getElementsByTagName('p');

    if (descriptionParagraphs.length === 0) {
      return;
    }

    setParsedDescription(descriptionParagraphs[0].innerHTML.replace(/(<([^>]+)>)/gi, ''));
  }, [descriptionRef]);

  return (
    <div>
      <div className="hidden" ref={descriptionRef} dangerouslySetInnerHTML={{ __html: description }} />
      <div className="text-volcaneers-black flex flex-col lg:flex-row lg:gap-48">
        <a href={link} target="_blank" className="md:hidden">
          <h2 className="font-bold font-heading text-24 leading-7 mb-16">{title}</h2>
        </a>
        <a href={link} target="_blank" className="block">
          <div className="lg:w-[540px] lg:h-[400px]">
            <img src={thumbnail} alt="" className="rounded-[12px] lg:object-cover lg:w-full lg:h-full" />
          </div>
        </a>
        <div className="mt-24 md:mt-32 lg:mt-0 md:flex-1 md:flex md:flex-col">
          <a href={link} target="_blank" className="hidden md:block">
            <h2 className="font-bold font-heading text-16 leading-5 md:text-48 md:leading-[48px]">{title}</h2>
          </a>
          {parsedDescription && (
            <p
              className="text-16 leading-6 md:mt-16 lg:max-h-[144px] lg:overflow-hidden"
              dangerouslySetInnerHTML={{ __html: parsedDescription }}
            />
          )}
          <div className="pt-16 md:pt-24 mt-auto flex items-center justify-between">
            <p className="text-16 leading-4 text-[#7A7B6F]">{formatPublicationDate(pubDate)}</p>
            <a href={link} target="_blank" className="text-16 leading-4 text-[#2DCA91] underline">
              <Trans>Read more</Trans>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPinnedPost;
